import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
import './lib/objectFitPolyfill.min';


$(document).foundation();
// Global Nav Position Setting
document.addEventListener('DOMContentLoaded', function () {
    const mq = window.matchMedia('(max-width: 1024px)');

    function checkBreakPoint(mq) {
        if (mq.matches) {
            $('.gNav').appendTo('.spNav');
            $('.gNav > .menu').removeClass('expanded');
            $('.subNav').appendTo('.spNav');
            $('.subNav ul').addClass('vertical');
            // $('.gNav > .menu').addClass('align-center');
        } else {
            $('.gNav').appendTo('.pcNav');
            $('.gNav > .menu').addClass('expanded');
            $('.subNav').appendTo('.title-wrapper');
            $('.subNav ul').removeClass('vertical');
            // $('.gNav > .menu').removeClass('align-center');
        }
    }
    mq.addListener(checkBreakPoint);
    checkBreakPoint(mq);
});

    // Page top
    $(function () {
        const pagetop = $('#page_top');
        pagetop.hide();
        $(window).scroll(function () {
            if ($(this).scrollTop() > 300) {  //300pxスクロールしたら表示
                pagetop.fadeIn();
            } else {
                pagetop.fadeOut();
            }
        });
    });
// Video playback

const videoElem = document.getElementById('video');
 
videoElem.addEventListener('loadedmetadata', function() {
    videoElem.playbackRate = 0.6;
});
$(function () {
  objectFitImages()
});